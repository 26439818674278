import { AddCircle, AttachMoney, ContentCopy, Delete, MoreVert, Search } from '@mui/icons-material';
import { Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticatedComponent from '../auth';
import { ApiError } from '../Components/core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../Components/core/DataTable';
import LoadingIndicator from '../Components/core/LoadingIndicator';
import { NavBreadcrumbs } from '../Components/core/NavBreadcrumbs';
import { BudgetListViewDto } from '../models/BudgetListViewDto';
import { useArchiveBudgetMutation, useGetBudgetsQuery, useUnarchiveBudgetMutation } from '../store/apis/budget-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../util/customHooks';
import { CopyBudgetModal } from './CopyBudgetModal';

export const BudgetsListView: FunctionComponent = () => {
    const navigate = useNavigate();
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | null>(null);
    const [selectedBudgetId, setSelectedBudgetId] = useState<string | null>(null);

    const { data, isLoading, error, refetch } = useGetBudgetsQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: showInactive,
    });
    const [selected, setSelected] = useState<readonly number[]>([]);
    const tableColumns: DataTableColumn<BudgetListViewDto>[] = [
        { key: 'name', label: 'Budget Name', sortKey: 'NAME' },
        { key: 'clientName', label: 'Client', sortKey: 'CLIENT', width: '150px' },
        { key: 'facilityName', label: 'Facility', sortKey: 'FACILITY', width: '175px' },
        { key: 'startYear', label: 'Start Year', sortKey: 'START_YEAR', width: '120px' },
        {
            key: 'isIncludedInBudgetSummaries',
            label: 'Include in Budget Summaries?',
            sortKey: 'isIncludedInBudgetSummaries',
            fieldMapper: (row) => (row.isIncludedInBudgetSummaries ? 'Yes' : 'No'),
            width: '180px',
        },
        {
            key: 'createdOn',
            label: 'Created',
            sortKey: 'CREATED_ON',
            fieldMapper: (row) => (row.createdOn ? format(new Date(row.createdOn.toString()), 'M/d/yyyy') : ''),
        },
    ];
    const [archiveBudget, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveBudgetMutation();
    const [unarchiveBudget, { isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveBudgetMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [isCopyBudgetModalOpen, setIsCopyBudgetModalOpen] = useState(false);

    const getEntityName = () => {
        return selected.length === 1 ? 'Budget' : 'Budgets';
    };

    const handleActionRequestCompletion = (resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveError, () => {
        handleActionRequestCompletion(resetArchive);
    });
    useFailedActionSnackbar('activate', getEntityName(), isUnarchiveError, () => {
        handleActionRequestCompletion(resetUnarchive);
    });
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetArchive);
    });
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveSuccess && !isUpdatingStatus, () => {
        handleActionRequestCompletion(resetUnarchive);
    });

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) afterClose();
    };

    const handleCreateNew = () => {
        navigate('/budgets/create');
    };

    const handleMenuEdit = useCallback(() => {
        const selectedId = data?.pageResults[selected[0]].id;
        if (selectedId) {
            navigate(`/budgets/edit/${selectedId}`);
        }
    }, [data?.pageResults, navigate, selected]);

    const handleTableEdit = (id: string) => {
        navigate(`/budgets/edit/${id}`);
    };

    const handleSetActive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveBudget(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    }, [data?.pageResults, selected, unarchiveBudget]);

    const handleSetInactive = useCallback(() => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveBudget(data?.pageResults[index].id!);
        });
        setIsUpdatingStatus(false);
    }, [archiveBudget, data?.pageResults, selected]);

    const handleContentCopy = useCallback(() => {
        if (selected.length === 1) {
            const budgetData = data?.pageResults.find((_, index) => index === selected[0]);
            if (budgetData) {
                setSelectedBudgetId(budgetData.id);
                setIsCopyBudgetModalOpen(true);
            }
        }
    }, [data?.pageResults, selected]);

    const canSetActive = useMemo(() => {
        return selected.some((index) => !data?.pageResults[index].isActive);
    }, [data?.pageResults, selected]);

    const canSetInactive = useMemo(() => {
        return selected.some((index) => data?.pageResults[index].isActive);
    }, [data?.pageResults, selected]);

    const canCopy = useMemo(() => {
        if (selected.length !== 1) {
            return false;
        }
        const selectedBudget = data?.pageResults[selected[0]];
        return selectedBudget?.isActive ?? false;
    }, [data?.pageResults, selected]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item container direction='row' alignItems='start' justifyContent={'space-between'} maxWidth={'100% !important'}>
                <Grid item container direction='column' justifyContent='start' xs={6}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <AttachMoney /> Budgets
                    </Typography>
                    <NavBreadcrumbs links={[{ label: 'Home', navLink: '/' }]} currentPageLabel='Budgets' />
                </Grid>
                <Grid item container direction='row' alignItems='center' xs={6} justifyContent={'end'}>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                            label='Show Inactive'
                            labelPlacement='start'
                        />
                    </Grid>
                    <AuthenticatedComponent
                        requiredPermissions={['delete:budgets']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Make Inactive'>
                                    <span>
                                        <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive}>
                                            <Delete fontSize='inherit' />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:workorders']}
                        component={
                            <Tooltip title='Copy'>
                                <Grid item>
                                    <IconButton color='primary' disabled={selected.length !== 1} onClick={handleContentCopy}>
                                        <ContentCopy />
                                    </IconButton>
                                </Grid>
                            </Tooltip>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:budgets']}
                        component={
                            <Grid item xs={1}>
                                <Tooltip title='Add New'>
                                    <IconButton color='primary' size='large' onClick={handleCreateNew}>
                                        <AddCircle fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    />
                    <AuthenticatedComponent
                        requiredPermissions={['create:budgets', 'edit:budgets', 'delete:budgets']}
                        logic='or'
                        component={
                            <Grid item xs={1}>
                                <IconButton size='large' onClick={handleMenuOpen}>
                                    <MoreVert fontSize='inherit' />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose()}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    <AuthenticatedComponent
                                        requiredPermissions={['create:budgets']}
                                        component={<MenuItem onClick={handleMenuClose(handleCreateNew)}>Add New</MenuItem>}
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['edit:budgets']}
                                        component={
                                            <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1}>
                                                Edit
                                            </MenuItem>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['create:budgets']}
                                        component={
                                            <MenuItem onClick={handleContentCopy} disabled={!canCopy}>
                                                Copy
                                            </MenuItem>
                                        }
                                    />
                                    <AuthenticatedComponent
                                        requiredPermissions={['delete:budgets']}
                                        component={
                                            <>
                                                <Divider />
                                                <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive}>
                                                    Make Active
                                                </MenuItem>
                                                <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive}>
                                                    Make Inactive
                                                </MenuItem>
                                            </>
                                        }
                                    />
                                </Menu>
                            </Grid>
                        }
                    />
                    <Grid item xs={5}>
                        <TextField
                            id='search-box'
                            value={searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Search'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item maxWidth={'100% !important'}>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading}
                    queryResults={data}
                    defaultSortKey='NAME'
                    setPagination={setPaginatedProps}
                    selected={selected}
                    setSelected={setSelected}
                    editPermissions={{ requiredPermissions: ['edit:budgets'] }}
                    onEdit={handleTableEdit}
                />
            </Grid>
            <CopyBudgetModal
                budgetId={selectedBudgetId}
                open={isCopyBudgetModalOpen}
                onCancel={() => {
                    setIsCopyBudgetModalOpen(false);
                    setSelectedBudgetId(null);
                }}
            />
        </Grid>
    );
};
