import { BudgetLineItemDto, BudgetLineItemPreviewDto, ExecutiveSummaryBudgetDto, QueryResultsDto } from '../../models';
import { BudgetLineItemListViewDto } from '../../models/BudgetLineItemListViewDto';
import { buildSearchQueryRoute } from '../../util';
import { serverApi } from '../api';
import { ChildSearchQueryParams, ChildQueryParams } from '../types';

const budgetLineItemApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getBudgetLineItems: builder.query<QueryResultsDto<BudgetLineItemListViewDto>, ChildSearchQueryParams>({
            query: (params) => buildSearchQueryRoute(`budget/${params.parentId}/budgetLineItem`, params.params),
            providesTags: [{ type: 'Budget Line Item' }, { type: 'Budget' }, { type: 'Budget Item' }, { type: 'Facility' }, { type: 'Client' }],
        }),
        getBudgetLineItem: builder.query<BudgetLineItemDto, ChildQueryParams>({
            query: (params) => `budget/${params.parentId}/budgetLineItem/${params.childId}`,
            providesTags: [{ type: 'Budget Line Item' }, { type: 'Budget' }, { type: 'Budget Item' }, { type: 'Facility' }, { type: 'Client' }],
        }),
        createBudgetLineItem: builder.mutation<BudgetLineItemDto, BudgetLineItemDto>({
            query: (budgetLineItem) => ({
                url: `budget/${budgetLineItem.budgetId}/budgetLineItem`,
                method: 'POST',
                body: budgetLineItem,
            }),
            invalidatesTags: [{ type: 'Budget' }, { type: 'Budget Line Item' }],
        }),
        generateBudgetLineItemPreview: builder.mutation<ExecutiveSummaryBudgetDto, BudgetLineItemPreviewDto>({
            query: (budgetLineItem) => ({
                url: `budget/${budgetLineItem.budgetId}/budgetLineItem/preview`,
                method: 'POST',
                body: budgetLineItem,
            }),
            invalidatesTags: [],
        }),
        archiveBudgetLineItem: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `budget/${params.parentId}/budgetLineItem/${params.childId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Budget Line Item' }],
        }),
        unarchiveBudgetLineItem: builder.mutation<void, ChildQueryParams>({
            query: (params) => ({
                url: `budget/${params.parentId}/budgetLineItem/${params.childId}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Budget Line Item' }],
        }),
        updateBudgetLineItem: builder.mutation<void, BudgetLineItemDto>({
            query: (budgetLineItem) => ({
                url: `budget/${budgetLineItem.budgetId}/budgetLineItem`,
                method: 'PUT',
                body: budgetLineItem,
            }),
            invalidatesTags: [{ type: 'Budget' }, { type: 'Budget Line Item' }],
        }),
    }),
});
export const {
    useGetBudgetLineItemsQuery,
    useLazyGetBudgetLineItemsQuery,
    useGetBudgetLineItemQuery,
    useLazyGetBudgetLineItemQuery,
    useCreateBudgetLineItemMutation,
    useArchiveBudgetLineItemMutation,
    useUnarchiveBudgetLineItemMutation,
    useUpdateBudgetLineItemMutation,
    useGenerateBudgetLineItemPreviewMutation,
} = budgetLineItemApi;
