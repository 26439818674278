import { AttachMoney } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import {
    Alert,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Paper,
    Select,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { BudgetDto, ClientDto, emptyGuid, FacilityDto, RoofProfileDto } from '../../../models';
import { createAddressStringFromFacility } from '../../../util';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { DashboardCard } from '../../Dashboard';
import { FormButton } from '../FormButton';
import { FormSection } from '../FormSection';
import { BudgetLineItemsCard } from './BudgetLineItemsCard';
import { ClientFacilityRoofPlanPicker } from '../FormFields/ClientFacilityRoofPlanPicker';
import { FormInput } from '../FormFields';
import { IFormProps } from '../types';

export const BudgetForm: FC<IFormProps<BudgetDto>> = ({ initValues, save, cancel }) => {
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [isIncludedInBudgetSummaries, setIsIncludedInBudgetSummaries] = useState(initValues ? initValues.isIncludedInBudgetSummaries : true);
    const [startYear, setStartYear] = useState(initValues ? initValues.startYear : null);
    const [budgetYearCount, setBudgetYearCount] = useState(initValues ? initValues.budgetYearCount : 5);
    const [client, setClient] = useState<ClientDto | undefined>(initValues?.facility?.client ?? undefined);
    const [budgetName, setBudgetName] = useState(initValues ? initValues.name : '');
    const [facility, setFacility] = useState<FacilityDto | null | undefined>(initValues ? initValues.facility : null);
    const [roofProfile, setRoofProfile] = useState<RoofProfileDto | null | undefined>(
        initValues?.roofProfile
    );

    const [fullAddress, setFullAddress] = useState(facility ? createAddressStringFromFacility(facility, client, null) : '');
    const [fieldErrors, setFieldErrors] = useState({
        BUDGET_NAME: '',
        FACILITY: '',
        START_YEAR: '',
        ROOF_PLAN: '',
    });
    const [formChanged, setFormChanged] = useState(false);

    useEffect(() => {
        if (facility) {
            setFullAddress(createAddressStringFromFacility(facility, client, null));
        }
    }, [facility, client]);

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsActive(event.target.checked);
    };

    const handleIsIncludedInBudgetSummariesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsIncludedInBudgetSummaries(event.target.checked);
    };

    const handleBudgetNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setBudgetName(event.target.value);
    };

    const handleStartYearChanged = (newValue: Date | null | undefined) => {
        setFormChanged(true);
        setStartYear(newValue);
    };

    const onFieldBlur = (fieldName: string) => () => {
        validate(fieldName);
    };

    const validate = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'BUDGET_NAME') {
            if (budgetName) {
                fieldErrors.BUDGET_NAME = '';
                isValid = true;
            } else {
                fieldErrors.BUDGET_NAME = 'Budget Name is required';
                isValid = false;
            }
        } else if (fieldName === 'FACILITY') {
            if (facility) {
                fieldErrors.FACILITY = '';
                isValid = true;
            } else {
                fieldErrors.FACILITY = 'Facility is required';
                isValid = false;
            }
        } else if (fieldName === 'ROOF_PLAN') {
            if (roofProfile) {
                fieldErrors.ROOF_PLAN = '';
                isValid = true;
            } else {
                fieldErrors.ROOF_PLAN = 'Roof Plan is required';
                isValid = false;
            }
        } else if (fieldName === 'START_YEAR') {
            if (startYear) {
                fieldErrors.START_YEAR = '';
                isValid = true;
            } else {
                fieldErrors.START_YEAR = 'Start year is required';
                isValid = false;
            }
        }
        setFieldErrors({
            BUDGET_NAME: fieldErrors.BUDGET_NAME,
            FACILITY: fieldErrors.FACILITY,
            START_YEAR: fieldErrors.START_YEAR,
            ROOF_PLAN: fieldErrors.ROOF_PLAN,
        });
        return isValid;
    };

    const formIsValid = () => {
        let isBudgetValid = validate('BUDGET_NAME');
        let isFacilityValid = validate('FACILITY');
        let isStartYearValid = validate('START_YEAR');
        let isRoofPlanValid = validate('ROOF_PLAN');
        if (isBudgetValid && isFacilityValid && isStartYearValid && isRoofPlanValid) {
            return true;
        }
    };

    const handleSave = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (formIsValid()) {
            save({
                ...initValues,
                id: initValues ? initValues.id : emptyGuid,
                name: budgetName,
                clientId: facility?.clientId,
                facilityId: facility?.id,
                roofProfileId: roofProfile?.id ?? "",
                budgetLineItems: [],
                isActive: isActive,
                startYear: startYear,
                budgetYearCount: budgetYearCount,
                isIncludedInBudgetSummaries: isIncludedInBudgetSummaries,
            });
            setFormChanged(false);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    const minYearCount = useMemo(() => {
        if (!initValues || !initValues.startYear) {
            return 0;
        }

        var budgetYearsWithAmounts = initValues.budgetLineItems.flatMap((lineItem) => (lineItem.budgetLineItemYears ?? [])).filter(year => year.amount !== null);
        if (budgetYearsWithAmounts.length === 0) {
            return 0;
        }
        var startYear = new Date(Date.parse(initValues.startYear.toString()));
        var budgetYearOffsets = budgetYearsWithAmounts.map(year => year.year - startYear.getFullYear());
        return Math.max(...budgetYearOffsets) + 1;
    }, [initValues]);

    const hasNumberOfYearsChanged = useMemo(() => initValues && initValues.id !== emptyGuid && budgetYearCount !== initValues.budgetYearCount, [budgetYearCount, initValues]);

    const isEdit = useMemo(() => initValues && initValues.id !== emptyGuid, [initValues]);

    return (
        <Grid component='form' container direction='column' spacing={1} autoComplete='off' onSubmit={handleSave} width={'inherit'}>
            <Grid position='sticky' item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <AttachMoney /> {budgetName ? budgetName : 'New Budget'}
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Home', navLink: '/' },
                            { label: 'Budgets', navLink: '/budgets' },
                        ]}
                        currentPageLabel={budgetName ? budgetName : 'New Budget'}
                    />
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                    <Grid item>
                        <FormControlLabel control={<Switch checked={isActive} onChange={handleActiveToggleChange} />} label='Active' labelPlacement='start' />
                    </Grid>
                    <Grid item>
                        {formChanged || !initValues ? (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Cancel
                            </FormButton>
                        ) : (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Close
                            </FormButton>
                        )}
                    </Grid>
                    <Grid item>
                        <FormButton variant='contained' color='primary' type='submit' onClick={handleSave}>
                            Save
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <DashboardCard headerTitle={`Budget`} headerIcon={<AttachMoney />}>
                    <FormSection hideBorder>
                        {fullAddress && (
                            <Grid item container direction='row'>
                                <Grid item xs={12}>
                                    <Paper variant='outlined'>
                                        <Typography sx={{ color: '#ffffff', backgroundColor: '#6596B9', textAlign: 'center', fontWeight: 'bold', whiteSpace: 'pre-line' }} padding={1}>
                                            {fullAddress}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item container direction='row'>
                            <Grid item container direction='row' alignItems='center' spacing={8} wrap='nowrap'>
                                <Grid item xs={4}>
                                    <FormInput
                                        label='Budget Name'
                                        onBlur={onFieldBlur('BUDGET_NAME')}
                                        value={budgetName}
                                        fullWidth
                                        required
                                        error={fieldErrors.BUDGET_NAME !== ''}
                                        errorText={fieldErrors.BUDGET_NAME}
                                        onChange={handleBudgetNameChange}
                                    />
                                </Grid>
                                <ClientFacilityRoofPlanPicker
                                    disabled={initValues != null}
                                    fieldErrors={fieldErrors}
                                    setFacility={setFacility}
                                    roofProfile={roofProfile}
                                    setRoofProfile={setRoofProfile}
                                    setFormChanged={setFormChanged}
                                    onFieldBlur={onFieldBlur}
                                    facility={facility}
                                    client={client}
                                    setClient={setClient}
                                />
                            </Grid>
                            <Grid item container direction='row' alignItems='center' spacing={8} wrap='nowrap'>
                                <Grid item xs={12} sm={4}>
                                    <FormControl error={fieldErrors.START_YEAR !== ''} fullWidth required disabled={isEdit}>
                                        <FormLabel>Start Year</FormLabel>
                                        <DatePicker
                                            value={startYear}
                                            onChange={handleStartYearChanged}
                                            views={['year']}
                                            disabled={isEdit}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={fieldErrors.START_YEAR !== ''}
                                                    onBlur={onFieldBlur('START_YEAR')}
                                                    required={true}
                                                    disabled={isEdit}
                                                />
                                            )}
                                        />
                                        <FormHelperText>{fieldErrors.START_YEAR}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth required>
                                        <FormLabel>Number of Years</FormLabel>
                                        <Select value={budgetYearCount} onChange={e => setBudgetYearCount(parseInt(e.target.value.toString()))}>
                                            <MenuItem value={1} disabled={1 < minYearCount}>1</MenuItem>
                                            <MenuItem value={2} disabled={2 < minYearCount}>2</MenuItem>
                                            <MenuItem value={3} disabled={3 < minYearCount}>3</MenuItem>
                                            <MenuItem value={4} disabled={4 < minYearCount}>4</MenuItem>
                                            <MenuItem value={5} disabled={5 < minYearCount}>5</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={<Switch checked={isIncludedInBudgetSummaries} onChange={handleIsIncludedInBudgetSummariesChange} />}
                                        label='Is Included in Budget Summaries'
                                        labelPlacement='start'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormSection>
                </DashboardCard>
            </Grid>
            {hasNumberOfYearsChanged && (
                <Grid item>
                    <Alert variant='filled' severity='warning'>
                        You are unable to edit Line Items until after saving changes to the Number of Years
                    </Alert>
                </Grid>
            )}
            <Grid item>
                {initValues && (
                    <BudgetLineItemsCard budget={initValues} facilityId={facility?.id!} roofProfileId={roofProfile?.id ?? ""} disableEdit={hasNumberOfYearsChanged} />
                )}
            </Grid>
        </Grid>
    );
};
