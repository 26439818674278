import { ContentCopy } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import LoadingIndicator from '../Components/core/LoadingIndicator';
import { useCreateBudgetCopyMutation, useLazyGetBudgetQuery } from '../store/apis/budget-api';
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../util';

interface ICopyBudgetModal {
    open: boolean;
    onCancel: () => void;
    budgetId: string | null;
}

export const CopyBudgetModal: FunctionComponent<ICopyBudgetModal> = React.memo((props) => {
    const { open, onCancel, budgetId } = props;
    const [getBudgetById, { data: budgetData, isFetching: isLoadingBudgetData }] = useLazyGetBudgetQuery();
    const [createCopyBudget, { isSuccess: copySuccess, isError: isCopyError, reset: resetCopy }] = useCreateBudgetCopyMutation();
    const [isIncludedInBudgetSummaries, setIsIncludedInBudgetSummaries] = useState<boolean>(true);
    const [startYear, setStartYear] = useState<Date | null>(budgetData && budgetData.startYear ? new Date(budgetData.startYear) : null);

    useSuccessfulActionSnackbar('copied', 'Budget', copySuccess, () => {
        resetCopy();
    });
    useFailedActionSnackbar('copy', 'Budget', isCopyError, resetCopy);

    useEffect(() => {
        setStartYear(budgetData && budgetData.startYear ? new Date(budgetData.startYear) : null);
    }, [budgetData]);

    const handleStartYearChange = useCallback((date: Date | null) => {
        setStartYear(date);
    }, []);

    const handleIsIncludedInBudgetSummariesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsIncludedInBudgetSummaries(event.target.checked);
    };

    const handleCancel = () => {
        setIsIncludedInBudgetSummaries(true);
        onCancel();
    };

    const handleSelect = () => {
        if (!budgetData) {
            return;
        }
        const budgetCopy = {
            id: budgetData.id,
            startYear: startYear,
            isIncludedInBudgetSummaries: isIncludedInBudgetSummaries,
        };

        createCopyBudget(budgetCopy);
        onCancel();
    };

    useEffect(() => {
        if (budgetId) {
            getBudgetById(budgetId);
        }
    }, [budgetId, getBudgetById]);

    return (
        <Dialog open={open}>
            <DialogTitle sx={{ backgroundColor: '#266490', color: '#ffffff', fontWeight: 'bold' }}>
                <Box display='flex' alignItems='center' gap={1}>
                    <ContentCopy />
                    <Typography fontSize='inherit' fontWeight='inherit'>
                        Copy Budget with New Start Year
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                {isLoadingBudgetData && <LoadingIndicator />}
                {budgetData && !isLoadingBudgetData && (
                    <Box display='flex' flexDirection='column' pt={4} gap={2}>
                        <Typography variant='h6'>Please enter the Start Year for the new Budget.</Typography>
                        <DatePicker
                            label='Start Year'
                            views={['year']}
                            onChange={handleStartYearChange}
                            value={startYear}
                            renderInput={(params) => <TextField {...params} required={true} />}
                        />

                        <Box>
                            <FormControlLabel
                                sx={{ marginLeft: '0' }}
                                control={<Switch checked={isIncludedInBudgetSummaries} onChange={handleIsIncludedInBudgetSummariesChange} />}
                                label='Hide Previous Budget From Budget Summaries'
                                labelPlacement='start'
                            />
                        </Box>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Box display='flex' justifyContent='flex-end' gap={2} p={2}>
                    <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button disabled={!startYear || isLoadingBudgetData} variant='contained' color='primary' onClick={handleSelect}>
                        Copy
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
});
