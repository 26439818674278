import { Download, ImportExport } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { ImportDto, emptyGuid } from '../../../../models';
import { ImportLineItemDto } from '../../../../models/ImportLineItemDto';
import { useCreateBudgetLineItemsImportMutation } from '../../../../store/apis/import-api';
import LoadingIndicator from '../../../core/LoadingIndicator';
import { StandardDialogHeader } from '../../StandardDialogHeader';
import { ImportLineItemsStepOne } from './ImportLineItemsStepOne';
import { ImportLineItemsStepThree } from './ImportLineItemsStepThree';
import { ImportLineItemsStepTwo } from './ImportLineItemsStepTwo';
import { IImportLineItemsDialog } from './types';

export const ImportLineItemsDialog: FunctionComponent<IImportLineItemsDialog> = (props) => {
    const { setIsImportVisible, isImportVisible, budgetId, budgetName, facilityId, onClose } = props;
    const [activeStep, setActiveStep] = useState(0);
    const [selectedFile, setSelectedFile] = useState<File>();
    const [isReadyForNext, setIsReadyForNext] = useState<Boolean>(false);
    const [parsedFileData, setParsedFileData] = useState<Array<ImportLineItemDto>>();
    const [updateExisting, setUpdateExisting] = useState<boolean>(false);
    const [createImport, { isLoading: importProcessing, isSuccess: importSucess, data: importResults, isError: importError }] =
        useCreateBudgetLineItemsImportMutation();

    const handleClose = useCallback(() => {
        setIsImportVisible(false);
        setSelectedFile(undefined);
        setIsReadyForNext(false);
        setParsedFileData(undefined);
        setUpdateExisting(false);
    }, [setIsImportVisible]);

    const handleDownloadSample = useCallback(() => {
        window.open(`${window.origin}/sample-budget-line-items.csv`);
    }, []);

    const handleImport = useCallback(
        (selectedFile: File | undefined) => {
            let filteredData = parsedFileData?.filter((x) => x.status === 'Good');
            let skippedRecords = (parsedFileData?.length ?? 0) - (filteredData?.length ?? 0);
            let importData: ImportDto = {
                isActive: true,
                id: emptyGuid,
                budgetId: budgetId,
                facilityId: facilityId!,
                importType: 'Budget Line Items',
                fileName: selectedFile?.name ?? '',
                updateExisting: updateExisting,
                parsedFile: filteredData!,
                recordsSkipped: skippedRecords ?? 0,
            };
            createImport(importData);
        },
        [facilityId, budgetId, createImport, parsedFileData, updateExisting]
    );

    const handleNext = useCallback(() => {
        if (activeStep === 1) {
            handleImport(selectedFile);
            setIsReadyForNext(true);
        }
        if (activeStep === 2) {
            handleClose();
            setActiveStep(0);
            return;
        }
        setActiveStep(activeStep + 1);
    }, [activeStep, handleClose, handleImport, selectedFile]);

    const steps = useMemo(
        () => [
            {
                content: <ImportLineItemsStepOne selectedFile={selectedFile} setSelectedFile={setSelectedFile} setIsReadyForNext={setIsReadyForNext} />,
                buttonText: 'Next',
            },
            {
                content: (
                    <ImportLineItemsStepTwo
                        selectedFile={selectedFile}
                        parsedFileData={parsedFileData}
                        setParsedFileData={setParsedFileData}
                        setIsReadyForNext={setIsReadyForNext}
                        budgetId={budgetId}
                        updateExisting={updateExisting}
                        setUpdateExisting={setUpdateExisting}></ImportLineItemsStepTwo>
                ),
                buttonText: 'Import',
            },
            {
                content:
                    (importProcessing && <LoadingIndicator></LoadingIndicator>) ||
                    (importError && <Typography sx={{ color: '#DF623B', fontWeight: 'bold' }}>Import failed, please try again.</Typography>) ||
                    (!importProcessing && importResults !== undefined && importSucess && (
                        <ImportLineItemsStepThree importResults={importResults!} isLoading={importProcessing}></ImportLineItemsStepThree>
                    )),
                buttonText: 'Close',
            },
        ],
        [budgetId, importError, importProcessing, importResults, importSucess, parsedFileData, selectedFile, updateExisting]
    );

    return (
        <Dialog
            open={isImportVisible}
            onClose={handleClose}
            aria-labelledby='modal-import-line-items'
            aria-describedby='modal-modal-import-line-items-by-csv'
            maxWidth='xl'
            PaperProps={{
                sx: {
                    backgroundColor: 'background.paper',
                    width: '100%',
                    ml: 35,
                    height: '70%',
                },
            }}>
            <StandardDialogHeader icon={<ImportExport sx={{ color: 'white' }} />} title='Import Budget Line Items' />
            <DialogContent>
                <Grid container direction='column'>
                    <Grid item>
                        <Typography id='modal-modal-description' sx={{ mt: 2, mb: 2 }}>
                            Line items will be added to {budgetName}.<br />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box sx={{ backgroundColor: 'background.paper' }}>
                            <Grid container direction='column' justifyContent={'space-between'}>
                                <Grid item xs={9}>
                                    <Box>{steps[activeStep].content}</Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{ mr: 4, py: 2 }}>
                {activeStep === 0 && (
                    <Button onClick={handleDownloadSample} size='large' sx={{ textAlign: 'center', marginRight: 'auto', minWidth: 360 }}>
                        <Download fontSize='small' sx={{ color: 'black' }} />{' '}
                        <Typography color={'secondary'}>Download Budget Line Items CSV template</Typography>
                    </Button>
                )}
                {activeStep !== 2 && (
                    <Button variant='outlined' style={{ boxShadow: 'none' }} onClick={onClose}>
                        {' '}
                        Cancel
                    </Button>
                )}
                <Button
                    variant='contained'
                    size='medium'
                    disabled={!isReadyForNext}
                    onClick={handleNext}
                    sx={{ color: 'secondary', backgroundColor: 'primary' }}>
                    {' '}
                    {steps[activeStep].buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
