import { AddCircle, Delete, FormatListBulleted, ImportExport, MoreVert, Search } from '@mui/icons-material';
import { Box, Divider, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip } from '@mui/material';
import { FC, useState } from 'react';
import { BudgetLineItemDto, RoofConditionDto } from '../../../models';
import { useArchiveBudgetLineItemMutation, useGetBudgetLineItemsQuery, useUnarchiveBudgetLineItemMutation } from '../../../store/apis/budget-line-item-api';
import { useFailedActionSnackbar, useStandardSnackbar, useSuccessfulActionSnackbar } from '../../../util/customHooks';
import { ApiError } from '../../core/ApiError';
import { DataTableColumn, PaginatedDataTable, PaginatedProps } from '../../core/DataTable';
import LoadingIndicator from '../../core/LoadingIndicator';
import { DashboardCard } from '../../Dashboard';
import { AddNewBudgetLineItemDialog } from './AddNewBudgetLineItemDialog';
import { ImportLineItemsDialog } from './ImportLineItems';
import { IBudgetLineItemsCard } from './types';
import { BudgetLineItemListViewDto } from '../../../models/BudgetLineItemListViewDto';

export const BudgetLineItemsCard: FC<IBudgetLineItemsCard> = ({ budget, facilityId, roofProfileId, disableEdit }) => {
    const [isAddNewLineItemOpen, setIsAddNewLineItemOpen] = useState(false);
    const [isImportOpen, setIsImportOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps | undefined>(undefined);
    const [showInactive, setShowInactive] = useState(false);
    const [dialogInstanceId, setDialogInstanceId] = useState(1);
    const [importDialogInstanceId, setImportDialogInstanceId] = useState(1);

    const {
        data: budgetLineItems,
        isLoading,
        error,
        refetch,
    } = useGetBudgetLineItemsQuery({
        parentId: budget.id,
        params: {
            searchText: searchText,
            sortKey: paginatedProps?.sortKey,
            sortAsc: paginatedProps?.sortAsc,
            page: paginatedProps?.page,
            pageSize: paginatedProps?.pageSize,
            includeInactive: showInactive,
        },
    });
    const [archiveBudgetLineItem, { isError: isArchiveError, isSuccess: isArchiveSuccess, reset: resetArchive }] = useArchiveBudgetLineItemMutation();
    const [unarchiveBudgetLineItem, { error: unarchiveError, isError: isUnarchiveError, isSuccess: isUnarchiveSuccess, reset: resetUnarchive }] = useUnarchiveBudgetLineItemMutation();
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [selectedLineItemId, setSelectedLineItemId] = useState<string | undefined>(undefined);

    const getEntityName = () => {
        return selected.length === 1 ? 'Budget line Item' : 'Budget line Items';
    };

    const handleActionRequestCompletion = (resetMethod: () => void | undefined) => {
        if (resetMethod) {
            resetMethod();
        }
        setIsUpdatingStatus(false);
        setSelected([]);
    };

    useFailedActionSnackbar('inactivate', getEntityName(), isArchiveError, () => { handleActionRequestCompletion(resetArchive); });
    useStandardSnackbar(isUnarchiveError, (unarchiveError as any)?.data ?? 'An Error Occurred', 'error', () => { handleActionRequestCompletion(resetUnarchive); });
    useSuccessfulActionSnackbar('Inactivated', getEntityName(), isArchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetArchive); });
    useSuccessfulActionSnackbar('Activated', getEntityName(), isUnarchiveSuccess && !isUpdatingStatus, () => { handleActionRequestCompletion(resetUnarchive); });

    const convertToCurrency = (value: number) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return formatter.format(value);
    };

    const calcTotal = (row: BudgetLineItemDto) => {
        return row.total ?? 0;
    };

    const makeSwatch = (condition: RoofConditionDto | undefined) => {
        return (
            <Grid container direction='row' alignItems='center' spacing={1}>
                <Grid item xs={2}>
                    <Box sx={{ display: 'flex', width: '16px', height: '16px', backgroundColor: condition?.colorCode }} />
                </Grid>
                <Grid item xs={10} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {condition?.rating}
                </Grid>
            </Grid>
        );
    };

    const tableColumns: DataTableColumn<BudgetLineItemListViewDto>[] = [
        { key: 'roofNumber', label: 'Roof #', sortKey: 'ROOF_NUMBER' },
        { key: 'squareFootage', label: 'Sq Ft', align: 'right', sortKey: 'SQUARE_FOOTAGE', fieldMapper: (row) => row.squareFootage.toLocaleString() },
        { key: 'assetValue', label: 'Asset Value', align: 'right', sortKey: 'ASSET_VALUE', fieldMapper: (row) => row.assetValue ? convertToCurrency(row.assetValue) : '' },
        { key: 'warrantyStatus', label: 'Warranty', sortKey: 'WARRANTY' },
        { key: 'roofSystemName', label: 'Roof Type', sortKey: 'ROOF_SYSTEM' },
        {
            key: 'currentCondition',
            label: 'Current Condition',
            sortKey: 'RATING',
            fieldMapper: (row) => makeSwatch(row.roofCondition),
        },
        { key: 'total', label: 'Total', align: 'right', sortKey: 'TOTAL', fieldMapper: (row) => convertToCurrency(calcTotal(row)) },
    ];

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setSearchText(event.target.value);
    };

    const handleCreateNew = () => {
        setDialogInstanceId(dialogInstanceId + 1);
        setIsAddNewLineItemOpen(true);
    };

    const handleMenuEdit = () => {
        setSelectedLineItemId(budgetLineItems?.pageResults[selected[0]].id);
        setIsAddNewLineItemOpen(true);
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected([]);
        setShowInactive(event.target.checked);
    };

    const handleImport = () => {
        setIsImportOpen(true);
    };

    const handleSetActive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            unarchiveBudgetLineItem({
                parentId: budget?.id!,
                childId: budgetLineItems?.pageResults[index]?.id!,
            });
        });
        setIsUpdatingStatus(false);
    };

    const handleSetInactive = () => {
        setIsUpdatingStatus(true);
        selected.forEach((index) => {
            archiveBudgetLineItem({
                parentId: budget?.id,
                childId: budgetLineItems?.pageResults[index]?.id!,
            });
        });
        setIsUpdatingStatus(false);
    };

    const canSetActive = () => {
        return selected.some((index) => !budgetLineItems?.pageResults[index].isActive);
    };

    const canSetInactive = () => {
        return selected.some((index) => budgetLineItems?.pageResults[index].isActive);
    };
    const handleRowEdit = (id: string) => {
        setSelectedLineItemId(id);
        setDialogInstanceId(dialogInstanceId + 1);
        setIsAddNewLineItemOpen(true);
        setSelected([]);
    };
    const handleAddLineItemClose = () => {
        setIsAddNewLineItemOpen(false);
        setSelectedLineItemId(undefined);
    };
    const handleImportClose = () => {
        setIsImportOpen(false);
        setImportDialogInstanceId(importDialogInstanceId + 1);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <DashboardCard headerIcon={<FormatListBulleted />} headerTitle='Line Items'>
            <Grid container direction='column' spacing={3} sx={{ paddingY: '24px', paddingX: '16px' }}>
                <Grid item container direction='row' alignItems='start' sx={{ padding: '0 24px' }}>
                    <Grid item container direction='column' justifyContent='start' xs={6}></Grid>
                    <Grid item container direction='row' alignItems='center' xs={6} justifyItems={'end'}>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Switch checked={showInactive} onChange={handleActiveToggleChange} />}
                                label='Show Inactive'
                                labelPlacement='start'
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title='Make Inactive'>
                                <span>
                                    <IconButton size='large' onClick={handleSetInactive} disabled={!canSetInactive() || disableEdit}>
                                        <Delete fontSize='inherit' />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title={'Import'}>
                                <IconButton color='primary' size='large' onClick={handleImport} disabled={disableEdit}>
                                    <ImportExport fontSize='inherit' />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title={'Add New'}>
                                <IconButton color='primary' size='large' onClick={handleCreateNew} disabled={disableEdit}>
                                    <AddCircle fontSize='inherit' />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton size='large' onClick={handleMenuOpen}>
                                <MoreVert fontSize='inherit' />
                            </IconButton>
                            <Menu
                                anchorEl={menuAnchorEl}
                                open={menuOpen}
                                onClose={handleMenuClose()}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}>
                                <MenuItem onClick={handleMenuClose(handleCreateNew)} disabled={disableEdit}>Add New</MenuItem>
                                <MenuItem onClick={handleMenuClose(handleMenuEdit)} disabled={selected.length !== 1 || disableEdit}>
                                    Edit
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleMenuClose(handleImport)} disabled={disableEdit}>Import</MenuItem>
                                <Divider />
                                <MenuItem onClick={handleMenuClose(handleSetActive)} disabled={!canSetActive() || disableEdit}>
                                    Make Active
                                </MenuItem>
                                <MenuItem onClick={handleMenuClose(handleSetInactive)} disabled={!canSetInactive() || disableEdit}>
                                    Make Inactive
                                </MenuItem>
                            </Menu>
                        </Grid>

                        <Grid item xs={5}>
                            <TextField
                                id='search-box'
                                value={searchText}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                label='Search'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <PaginatedDataTable
                        columns={tableColumns}
                        loading={isLoading}
                        queryResults={budgetLineItems}
                        defaultSortKey='ROOF_NUMBER'
                        setPagination={setPaginatedProps}
                        selected={selected}
                        setSelected={setSelected}
                        onEdit={disableEdit ? undefined : handleRowEdit}
                    />
                </Grid>
            </Grid>
            <AddNewBudgetLineItemDialog
                key={`add-new-${dialogInstanceId}`}
                open={isAddNewLineItemOpen}
                onClose={handleAddLineItemClose}
                facilityId={facilityId!}
                budgetLineItemId={selectedLineItemId}
                roofProfileId={roofProfileId}
                budgetId={budget.id}
                budgetStartYear={budget.startYear}
                budgetYearCount={budget.budgetYearCount}
            />
            <ImportLineItemsDialog
                key={`import-${importDialogInstanceId}`}
                setIsImportVisible={setIsImportOpen}
                onClose={handleImportClose}
                isImportVisible={isImportOpen}
                budgetId={budget.id}
                budgetName={budget.name}
                facilityId={facilityId!}
            />
        </DashboardCard>
    );
};
