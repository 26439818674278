import { serverApi } from '..';
import { BudgetDto, QueryResultsDto } from '../../models';
import { BudgetCopyDto } from '../../models/BudgetCopyDto';
import { BudgetListViewDto } from '../../models/BudgetListViewDto';
import { buildSearchQueryRoute } from '../../util';
import { SearchQueryParams } from '../types';

const budgetApi = serverApi.injectEndpoints({
    endpoints: (builder) => ({
        getBudgets: builder.query<QueryResultsDto<BudgetListViewDto>, SearchQueryParams>({
            query: (params) => buildSearchQueryRoute('budget', params),
            providesTags: [{ type: 'Budget' }],
        }),
        getBudget: builder.query<BudgetDto, string>({
            query: (id) => ({ url: `budget/${id}` }),
            providesTags: [{ type: 'Budget' }],
        }),
        createBudget: builder.mutation<BudgetDto, BudgetDto>({
            query: (budgetDto) => ({
                url: 'budget',
                method: 'POST',
                body: budgetDto,
            }),
            invalidatesTags: [{ type: 'Budget' }, { type: 'Budget Line Item' }],
        }),
        updateBudget: builder.mutation<void, BudgetDto>({
            query: (budgetDto) => ({
                url: 'budget',
                method: 'PUT',
                body: budgetDto,
            }),
            invalidatesTags: [{ type: 'Budget' }, { type: 'Budget Line Item' }],
        }),
        archiveBudget: builder.mutation<void, string>({
            query: (id) => ({
                url: `budget/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Budget' }, { type: 'Budget Line Item' }],
        }),
        unarchiveBudget: builder.mutation<void, string>({
            query: (id) => ({
                url: `budget/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Budget' }, { type: 'Budget Line Item' }],
        }),
        createBudgetCopy: builder.mutation<BudgetDto, BudgetCopyDto>({
            query: (budgetCopy) => ({
                url: `budget/copy`,
                method: 'POST',
                body: budgetCopy,
            }),
            invalidatesTags: [{ type: 'Budget' }, { type: 'Budget Line Item' }],
        }),
    }),
});

export const {
    useGetBudgetsQuery,
    useGetBudgetQuery,
    useLazyGetBudgetQuery,
    useCreateBudgetMutation,
    useUpdateBudgetMutation,
    useArchiveBudgetMutation,
    useUnarchiveBudgetMutation,
    useCreateBudgetCopyMutation,
} = budgetApi;
