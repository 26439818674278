import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Switch,
    Typography,
} from '@mui/material';
import { Business, Delete, People } from '@mui/icons-material';
import { ClientDto, emptyGuid, PhotoDto, usStates } from '../../../models';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { Attachment, FormButton, FormSection, IFormProps } from '..';
import { DashboardCard } from '../../Dashboard';
import { ClientFacilities } from './ClientFacilities';
import AuthenticatedComponent from '../../../auth';
import { isEmail } from '../../../util/isEmail';
import { useLazyGetPhotoFileLinkQuery } from '../../../store/apis/photo-api';
import { UploadPhotoDialog } from '../../core/Photos';
import { FormInput } from '../FormFields';


export const ClientForm: FunctionComponent<IFormProps<ClientDto>> = (props) => {
    const { save, cancel, initValues } = props;
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [name, setName] = useState(initValues ? initValues.name : '');
    const [addressLine1, setAddressLine1] = useState(initValues ? initValues.addressLine1 : '');
    const [addressLine2, setAddressLine2] = useState(initValues ? initValues.addressLine2 : '');
    const [city, setCity] = useState(initValues ? initValues.city : '');
    const [state, setState] = useState(initValues ? initValues.state : '');
    const [zipCode, setZipCode] = useState(initValues ? initValues.zipCode : '');
    const [billingEmail, setBillingEmail] = useState(initValues ? initValues.billingEmail : '');
    const [phone, setPhone] = useState(initValues ? initValues.phone : '');
    const [contactName, setContactName] = useState(initValues ? initValues.contactName : '');
    const [contactEmail, setContactEmail] = useState(initValues ? initValues.contactEmail : '');
    const [contactPhone, setContactPhone] = useState(initValues ? initValues.contactPhone : '');
    const [website, setWebsite] = useState(initValues ? initValues.website : '');
    const [addPhotoIsOpen, setAddPhotoIsOpen] = useState(false);
    const [imageHover, setImageHover] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({
        NAME: '',
        ADDRESS_LINE_1: '',
        CITY: '',
        STATE: '',
        ZIP_CODE: '',
        EMAIL: '',
        CONTACT_EMAIL: '',
    });
    const [changed, setFormChanged] = useState(false);
    const [clientLogo, setClientLogo] = useState<Attachment | undefined>();
    const [logoDisplayText, setLogoDisplayText] = useState(clientLogo ? clientLogo.photo.displayName : '');

    const [getFileLink] = useLazyGetPhotoFileLinkQuery();

    const handleActiveToggleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsActive(event.target.checked);
    }, []);

    const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setName(event.target.value);
    }, []);

    const handleAddressLine1Change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setAddressLine1(event.target.value);
    }, []);

    const handleAddressLine2Change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setAddressLine2(event.target.value);
    }, []);

    const handleCityChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setCity(event.target.value);
    }, []);

    const handleStateChange = useCallback((event: SelectChangeEvent) => {
        setFormChanged(true);
        setState(event.target.value);
    }, []);

    const handleZipCodeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setZipCode(event.target.value);
    }, []);

    const handleBillingEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setBillingEmail(event.target.value);
    }, []);

    const handlePhoneChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPhone(event.target.value);
    }, []);

    const handleContactNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setContactName(event.target.value);
    }, []);

    const handleContactEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setContactEmail(event.target.value);
    }, []);

    const handleContactPhoneChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setContactPhone(event.target.value);
    }, []);

    const handleWebsiteChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setWebsite(event.target.value);
    }, []);

    
    const validate = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'NAME') {
            if (name) {
                fieldErrors.NAME = '';
                isValid = true;
            } else {
                fieldErrors.NAME = 'Client Name is required';
                isValid = false;
            }
        } else if (fieldName === 'ADDRESS_LINE_1') {
            if (addressLine1) {
                fieldErrors.ADDRESS_LINE_1 = '';
                isValid = true;
            } else {
                fieldErrors.ADDRESS_LINE_1 = 'Address Line 1 is required';
                isValid = false;
            }
        } else if (fieldName === 'CITY') {
            if (city) {
                fieldErrors.CITY = '';
                isValid = true;
            } else {
                fieldErrors.CITY = 'City is required';
                isValid = false;
            }
        } else if (fieldName === 'STATE') {
            if (state) {
                fieldErrors.STATE = '';
                isValid = true;
            } else {
                fieldErrors.STATE = 'State is required';
                isValid = false;
            }
        } else if (fieldName === 'ZIP_CODE') {
            if (zipCode) {
                fieldErrors.ZIP_CODE = '';
                isValid = true;
            } else {
                fieldErrors.ZIP_CODE = 'Zip Code is required';
                isValid = false;
            }
        } else if (fieldName === 'EMAIL') {
            if (isEmail(billingEmail) || billingEmail === '') {
                fieldErrors.EMAIL = '';
                isValid = true;
            } else {
                fieldErrors.EMAIL = 'Must be a valid email address';
                isValid = false;
            }
        } else if (fieldName === 'CONTACT_EMAIL') {
            if (isEmail(contactEmail)) {
                fieldErrors.CONTACT_EMAIL = '';
                isValid = true;
            } else {
                fieldErrors.CONTACT_EMAIL = 'Must be a valid email address';
                isValid = false;
            }
        }
        setFieldErrors({
            NAME: fieldErrors.NAME,
            ADDRESS_LINE_1: fieldErrors.ADDRESS_LINE_1,
            CITY: fieldErrors.CITY,
            STATE: fieldErrors.STATE,
            ZIP_CODE: fieldErrors.ZIP_CODE,
            EMAIL: fieldErrors.EMAIL,
            CONTACT_EMAIL: fieldErrors.CONTACT_EMAIL,
        });
        return isValid;
    };

    const formIsValid = () => {
        let isValid = validate('NAME');
        isValid = validate('ADDRESS_LINE_1') && isValid;
        isValid = validate('CITY') && isValid;
        isValid = validate('STATE') && isValid;
        isValid = validate('ZIP_CODE') && isValid;
        isValid = validate('EMAIL') && isValid;
        isValid = validate('CONTACT_EMAIL') && isValid;
        return isValid;
    };

    const onFieldBlur = (fieldName: string) => () => {
        validate(fieldName);
    };

    const handleDelete = useCallback(() => {
        setClientLogo(undefined);
        setLogoDisplayText('');
    }, []);

    const afterPhotoUpload = useCallback((id: string, photo: PhotoDto, previewLink: string) => {
        setFormChanged(true);
        setClientLogo({ id: id, photo: photo, previewLink: previewLink });
        setLogoDisplayText(photo.displayName);
    }, []);

    const createPreviewLink = useCallback(
        async (attachment: PhotoDto): Promise<Attachment> => {
            let response = await getFileLink(attachment.id, false);
            return { id: attachment.id, photo: attachment, previewLink: response.data?.link };
        },
        [getFileLink]
    );

    const initializeClientLogo = useCallback(() => {
        if (initValues?.clientLogo) {
            createPreviewLink(initValues.clientLogo).then((clientLogo) => {
                setClientLogo(clientLogo);
                setLogoDisplayText(clientLogo.photo.displayName);
            });
        }
    }, [createPreviewLink, initValues?.clientLogo]);

    useEffect(() => {
        initializeClientLogo();
    }, [initializeClientLogo]);

    const handleSave = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (formIsValid()) {
            save({
                id: initValues ? initValues.id : emptyGuid,
                isActive: isActive,
                name: name,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                state: state,
                zipCode: zipCode,
                billingEmail: billingEmail,
                phone: phone,
                contactName: contactName,
                contactEmail: contactEmail,
                contactPhone: contactPhone,
                clientLogoId: clientLogo?.photo.id ?? undefined,
                clientLogo: clientLogo?.photo,
                website: website,
            });
            setFormChanged(false);
        }
    };

    

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSave}>
            <Grid position='sticky' item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <People /> {name ? name : 'New Client'}
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Home', navLink: '/' },
                            { label: 'Clients', navLink: '/clients' },
                        ]}
                        currentPageLabel={name ? name : 'New Client'}
                    />
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                    <Grid item>
                        <FormControlLabel control={<Switch checked={isActive} onChange={handleActiveToggleChange} />} label='Active' labelPlacement='start' />
                    </Grid>
                    <Grid item>
                        {changed || !initValues ? (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Cancel
                            </FormButton>
                        ) : (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Close
                            </FormButton>
                        )}
                    </Grid>
                    <Grid item>
                        <FormButton variant='contained' color='primary' type='submit' onClick={handleSave}>
                            Save
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <FormSection>
                    <Grid item container direction='row' columnSpacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='Client Name'
                                onBlur={onFieldBlur('NAME')}
                                value={name}
                                fullWidth
                                required
                                error={fieldErrors.NAME !== ''}
                                errorText={fieldErrors.NAME}
                                onChange={handleNameChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Address Line 1'
                                onBlur={onFieldBlur('ADDRESS_LINE_1')}
                                value={addressLine1}
                                fullWidth
                                required
                                error={fieldErrors.ADDRESS_LINE_1 !== ''}
                                errorText={fieldErrors.ADDRESS_LINE_1}
                                onChange={handleAddressLine1Change}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Address Line 2'
                                value={addressLine2}
                                fullWidth
                                onChange={handleAddressLine2Change}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='City'
                                onBlur={onFieldBlur('CITY')}
                                value={city}
                                fullWidth
                                required
                                error={fieldErrors.CITY !== ''}
                                errorText={fieldErrors.CITY}
                                onChange={handleCityChange}
                            />
                        </Grid>
                        <Grid item container direction='row' spacing={4} xs={4}>
                            <Grid item xs={8}>
                                <FormControl error={fieldErrors.STATE !== ''} fullWidth required>
                                    <FormLabel>State</FormLabel>
                                    <Select value={state} onChange={handleStateChange} onBlur={onFieldBlur('STATE')}>
                                        {usStates.map((usState) => (
                                            <MenuItem key={usState.abbreviation} value={usState.abbreviation}>
                                                {usState.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{fieldErrors.STATE}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormInput
                                    label='Zip Code'
                                    onBlur={onFieldBlur('ZIP_CODE')}
                                    value={zipCode}
                                    fullWidth
                                    required
                                    error={fieldErrors.ZIP_CODE !== ''}
                                    errorText={fieldErrors.ZIP_CODE}
                                    onChange={handleZipCodeChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='Billing Email'
                                onBlur={onFieldBlur('EMAIL')}
                                value={billingEmail}
                                fullWidth
                                required
                                error={fieldErrors.EMAIL !== ''}
                                errorText={fieldErrors.EMAIL}
                                onChange={handleBillingEmailChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Phone'
                                value={phone}
                                fullWidth
                                onChange={handlePhoneChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Website'
                                value={website}
                                fullWidth
                                onChange={handleWebsiteChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='Contact Name'
                                value={contactName}
                                fullWidth
                                onChange={handleContactNameChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Contact Email'
                                value={contactEmail}
                                fullWidth
                                error={fieldErrors.CONTACT_EMAIL !== ''}
                                errorText={fieldErrors.CONTACT_EMAIL}
                                onChange={handleContactEmailChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Contact Phone'
                                value={contactPhone}
                                fullWidth
                                onChange={handleContactPhoneChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={4}>
                        <Grid item mt={4} xs={2}>
                            <Button
                                variant='contained'
                                component='span'
                                size='medium'
                                color='secondary'
                                onClick={() => {
                                    setAddPhotoIsOpen(true);
                                }}>
                                Choose File
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel>Client Logo</FormLabel>
                                <OutlinedInput value={logoDisplayText} disabled={true} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sx={{ maxWidth: '225px', maxHeight: '225px' }}>
                            {clientLogo && (
                                <Box position='relative'>
                                    <img
                                        src={clientLogo?.previewLink}
                                        alt={clientLogo?.photo.displayName ?? 'Attachment'}
                                        onMouseEnter={() => {
                                            setImageHover(true);
                                        }}
                                        onMouseLeave={() => {
                                            setImageHover(false);
                                        }}
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'contain',
                                            border: 'solid',
                                            borderWidth: '1px',
                                            borderColor: '#e1e1e1',
                                        }}
                                    />
                                    {imageHover && (
                                        <IconButton
                                            size='large'
                                            disabled={!clientLogo}
                                            onMouseEnter={() => {
                                                setImageHover(true);
                                            }}
                                            onClick={handleDelete}
                                            style={{
                                                position: 'absolute',
                                                padding: 2,
                                                bottom: 10,
                                                right: 10,
                                                fill: 'white',
                                                backgroundColor: '#eeeeee',
                                            }}>
                                            <Delete fontSize='inherit' />
                                        </IconButton>
                                    )}
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    <UploadPhotoDialog
                        open={addPhotoIsOpen}
                        onClose={() => setAddPhotoIsOpen(false)}
                        afterPhotoUpload={afterPhotoUpload}
                        allowedFileTypes={['jpg', 'jpeg', 'png']}
                        shouldResizeFiles={false}
                    />
                </FormSection>
            </Grid>
            {initValues && (
                <AuthenticatedComponent
                    requiredPermissions={['read:facilities']}
                    component={
                        <Grid item>
                            <DashboardCard headerTitle='Facilities' headerIcon={<Business />}>
                                <ClientFacilities clientId={initValues.id} />
                            </DashboardCard>
                        </Grid>
                    }
                />
            )}
        </Grid>
    );
};
