import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import {
    Box,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import { Business, Help, Layers } from '@mui/icons-material';
import { emptyGuid, usStates } from '../../../models';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { FormButton, FormSection } from '..';
import { IFacilityFormProps } from './types';
import { FormInput, FormNumberInput, patterns } from '../FormFields';
import { RoofProfiles } from './RoofProfiles';
import { DashboardCard } from '../../Dashboard';
import { isEmail } from '../../../util/isEmail';

export const FacilityForm: FunctionComponent<IFacilityFormProps> = (props) => {
    const { save, cancel, initValues, client } = props;
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [name, setName] = useState(initValues ? initValues.name : '');
    const [addressLine1, setAddressLine1] = useState(initValues ? initValues.addressLine1 : '');
    const [addressLine2, setAddressLine2] = useState(initValues ? initValues.addressLine2 : '');
    const [city, setCity] = useState(initValues ? initValues.city : '');
    const [state, setState] = useState(initValues ? initValues.state : '');
    const [zipCode, setZipCode] = useState(initValues ? initValues.zipCode : '');
    const [phone, setPhone] = useState(initValues ? initValues.phone : '');
    const [fax, setFax] = useState(initValues ? initValues.fax : '');
    const [email, setEmail] = useState(initValues ? initValues.email : '');
    const [website, setWebsite] = useState(initValues ? initValues.website : '');
    const [yearEnd, setYearEnd] = useState(initValues && initValues.yearEnd !== 0 ? initValues.yearEnd?.toString() : '');
    const [formChanged, setFormChanged] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({
        NAME: '',
        ADDRESS_LINE_1: '',
        CITY: '',
        STATE: '',
        ZIP_CODE: '',
        YEAR_END: '',
        EMAIL: '',
    });

    const [droneFootageUrl, setDroneFootageUrl] = useState(!!initValues && !!initValues.droneFootageUrl ? initValues.droneFootageUrl : '');

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsActive(event.target.checked);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setName(event.target.value);
    };

    const handleAddressLine1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setAddressLine1(event.target.value);
    };

    const handleAddressLine2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setAddressLine2(event.target.value);
    };

    const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setCity(event.target.value);
    };

    const handleStateChange = (event: SelectChangeEvent) => {
        setFormChanged(true);
        setState(event.target.value);
    };

    const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setZipCode(event.target.value);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setPhone(event.target.value);
    };

    const handleFaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setFax(event.target.value);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setEmail(event.target.value);
    };

    const handleWebsiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setWebsite(event.target.value);
    };

    const handleYearEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        if (!event.target.validity.patternMismatch) {
            setYearEnd(event.target.value);
        }
    };

    const handleDroneFootageUrlChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setDroneFootageUrl(event.target.value);
    }, [setDroneFootageUrl]);


    const onFieldBlur = (fieldName: string) => () => {
        validate(fieldName);
    };

    const isValidDroneFootageUrl = useMemo(() => {
        const urlPattern = /(?:youtube(?:-nocookie)?\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        return urlPattern.test(droneFootageUrl); 
    }, [droneFootageUrl]);

    const validate = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'NAME') {
            if (name) {
                fieldErrors.NAME = '';
                isValid = true;
            } else {
                fieldErrors.NAME = 'Client Name is required';
                isValid = false;
            }
        } else if (fieldName === 'ADDRESS_LINE_1') {
            if (addressLine1) {
                fieldErrors.ADDRESS_LINE_1 = '';
                isValid = true;
            } else {
                fieldErrors.ADDRESS_LINE_1 = 'Address Line 1 is required';
                isValid = false;
            }
        } else if (fieldName === 'CITY') {
            if (city) {
                fieldErrors.CITY = '';
                isValid = true;
            } else {
                fieldErrors.CITY = 'City is required';
                isValid = false;
            }
        } else if (fieldName === 'STATE') {
            if (state) {
                fieldErrors.STATE = '';
                isValid = true;
            } else {
                fieldErrors.STATE = 'State is required';
                isValid = false;
            }
        } else if (fieldName === 'ZIP_CODE') {
            if (zipCode) {
                fieldErrors.ZIP_CODE = '';
                isValid = true;
            } else {
                fieldErrors.ZIP_CODE = 'Zip Code is required';
                isValid = false;
            }
        } else if (fieldName === 'YEAR_END') {
            const yearRegex = patterns.Year.validate;
            if (yearRegex.test(yearEnd ?? '')) {
                fieldErrors.YEAR_END = '';
                isValid = true;
            } else {
                fieldErrors.YEAR_END = 'Year End must be in format YYYY';
                isValid = false;
            }
        } else if (fieldName === 'EMAIL') {
            if (isEmail(email) || email === '') {
                fieldErrors.EMAIL = '';
                isValid = true;
            } else {
                fieldErrors.EMAIL = 'Must be a valid email address';
                isValid = false;
            }
        }
        setFieldErrors({
            NAME: fieldErrors.NAME,
            ADDRESS_LINE_1: fieldErrors.ADDRESS_LINE_1,
            CITY: fieldErrors.CITY,
            STATE: fieldErrors.STATE,
            ZIP_CODE: fieldErrors.ZIP_CODE,
            YEAR_END: fieldErrors.YEAR_END,
            EMAIL: fieldErrors.EMAIL,
        });
        return isValid;
    };

    const formIsValid = () => {
        let isValid = validate('NAME');
        isValid = validate('ADDRESS_LINE_1') && isValid;
        isValid = validate('CITY') && isValid;
        isValid = validate('STATE') && isValid;
        isValid = validate('ZIP_CODE') && isValid;
        isValid = validate('EMAIL') && isValid;
        return isValid;
    };

    const handleSave = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (formIsValid()) {
            save({
                id: initValues ? initValues.id : emptyGuid,
                isActive: isActive,
                name: name,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                state: state,
                zipCode: zipCode,
                phone: phone,
                fax: fax,
                email: email,
                website: website,
                yearEnd: yearEnd ? parseInt(yearEnd) : 0,
                roofProfiles: initValues ? initValues.roofProfiles : undefined,
                clientId: client.id,
                client: client,
                droneFootageUrl: droneFootageUrl
            });
            setFormChanged(false);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off' onSubmit={handleSave}>
            <Grid position='sticky' item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <Business /> {name ? name : 'New Facility'}
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Home', navLink: '/' },
                            { label: 'Clients', navLink: '/clients' },
                            { label: client.name, navLink: `/clients/${client.id}` },
                        ]}
                        currentPageLabel={name ? name : 'New Facility'}
                    />
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                    <Grid item>
                        <FormControlLabel control={<Switch checked={isActive} onChange={handleActiveToggleChange} />} label='Active' labelPlacement='start' />
                    </Grid>
                    <Grid item>
                        {formChanged || !initValues ? (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Cancel
                            </FormButton>
                        ) : (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Close
                            </FormButton>
                        )}
                    </Grid>
                    <Grid item>
                        <FormButton variant='contained' color='primary' type='submit' onClick={handleSave}>
                            Save
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <FormSection>
                    <Grid item container direction='row' columnSpacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='Facility Name'
                                onBlur={onFieldBlur('NAME')}
                                value={name}
                                fullWidth
                                required
                                error={fieldErrors.NAME !== ''}
                                errorText={fieldErrors.NAME}
                                onChange={handleNameChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Address Line 1'
                                onBlur={onFieldBlur('ADDRESS_LINE_1')}
                                value={addressLine1}
                                fullWidth
                                required
                                error={fieldErrors.ADDRESS_LINE_1 !== ''}
                                errorText={fieldErrors.ADDRESS_LINE_1}
                                onChange={handleAddressLine1Change}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Address Line 2'
                                value={addressLine2}
                                fullWidth
                                onChange={handleAddressLine2Change}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='City'
                                onBlur={onFieldBlur('CITY')}
                                value={city}
                                fullWidth
                                required
                                error={fieldErrors.CITY !== ''}
                                errorText={fieldErrors.CITY}
                                onChange={handleCityChange}
                            />
                        </Grid>
                        <Grid item container direction='row' spacing={4} xs={4}>
                            <Grid item xs={8}>
                                <FormControl error={fieldErrors.STATE !== ''} fullWidth required>
                                    <FormLabel>State</FormLabel>
                                    <Select value={state} onChange={handleStateChange} onBlur={onFieldBlur('STATE')}>
                                        {usStates.map((usState) => (
                                            <MenuItem key={usState.abbreviation} value={usState.abbreviation}>
                                                {usState.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{fieldErrors.STATE}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormInput
                                    label='Zip Code'
                                    onBlur={onFieldBlur('ZIP_CODE')}
                                    value={zipCode}
                                    fullWidth
                                    required
                                    error={fieldErrors.ZIP_CODE !== ''}
                                    errorText={fieldErrors.ZIP_CODE}
                                    onChange={handleZipCodeChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='Phone'
                                value={phone}
                                fullWidth
                                onChange={handlePhoneChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Fax'
                                value={fax}
                                fullWidth
                                onChange={handleFaxChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label='Email'
                                onBlur={onFieldBlur('EMAIL')}
                                value={email}
                                fullWidth
                                required
                                error={fieldErrors.EMAIL !== ''}
                                errorText={fieldErrors.EMAIL}
                                onChange={handleEmailChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                            <FormInput
                                label='Website'
                                value={website}
                                fullWidth
                                onChange={handleWebsiteChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormNumberInput
                                value={yearEnd}
                                onChange={handleYearEndChange}
                                onBlur={onFieldBlur('YEAR_END')}
                                label='Year End'
                                name='yearEnd'
                                errorText={fieldErrors.YEAR_END}
                                error={fieldErrors.YEAR_END !== ''}
                                fullWidth
                                inputProps={{ inputMode: 'numeric', pattern: patterns.Year.input.source }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                    
                        <FormControl fullWidth>
                            <Box><FormLabel>Drone Footage</FormLabel> <Tooltip title='Provide a link to a ‘Public’ or ’Unlisted’ Youtube video.'><IconButton><Help/></IconButton></Tooltip></Box>
                            <OutlinedInput value={droneFootageUrl} onChange={handleDroneFootageUrlChange} error={!isValidDroneFootageUrl && !!droneFootageUrl}/>
                        </FormControl>
                    
                    </Grid>
                </FormSection>
            </Grid>
            {initValues && (
                <Grid item>
                    <DashboardCard headerTitle='Roof Plans' headerIcon={<Layers />}>
                        <RoofProfiles facilityId={initValues.id} clientId={client.id} />
                    </DashboardCard>
                </Grid>
            )}
        </Grid>
    );
};
