import { FunctionComponent, useEffect, useState } from "react";
import { BudgetLineItemDto, BudgetLineItemYearDto, ExecutiveSummaryBudgetDto, emptyGuid } from "../../../models";
import { ExecutiveSummaryBudgetCard } from "../../Dashboard/Components/types";
import { useGenerateBudgetLineItemPreviewMutation } from "../../../store/apis/budget-line-item-api";
import LoadingIndicator from "../../core/LoadingIndicator";

export interface IBudgetLineItemPreviewProps {
    isVisible: boolean;
    budgetId?: string;
    roofSectionId?: string;
    squareFeet?: number;
    lineItemYears?: BudgetLineItemYearDto[];
};

export const BudgetLineItemPreview: FunctionComponent<IBudgetLineItemPreviewProps> = (props) => {
    const { isVisible, budgetId, roofSectionId, squareFeet, lineItemYears } = props;

    const [summary, setSummary] = useState<ExecutiveSummaryBudgetDto>();
    const [generatePreview] =
        useGenerateBudgetLineItemPreviewMutation();

    useEffect(() => {
        if (isVisible && budgetId && roofSectionId && squareFeet && lineItemYears && summary === undefined) {
            try {
                generatePreview({
                    budgetId: budgetId ?? '',
                    budgetLineItem: {
                        id: emptyGuid,
                        isActive: true,
                        budgetId: budgetId!,
                        roofName: '',
                        squareFootage: squareFeet!,
                        assetValueMultiplier: 1.0,
                        assetValue: 0,
                        warranty: undefined,
                        roofSystemId: undefined,
                        recommendedAction: '',
                        currentConditionId: emptyGuid,
                        budgetLineItemYears: lineItemYears
                    } as BudgetLineItemDto,
                    roofSectionId: roofSectionId ?? ''
                }).then((result) => {
                    if (result) {
                        if ((result as any).data) {
                            const summary = (result as any).data;
                            setSummary(summary);
                        }
                    }
                });
            } catch {
                setSummary(undefined);
            }
        } else if (!isVisible && summary !== undefined) {
            setSummary(undefined);
        }
    }, [budgetId, generatePreview, isVisible, lineItemYears, roofSectionId, squareFeet, summary]);

    return (summary && isVisible ? <ExecutiveSummaryBudgetCard budgetSummary={summary} /> : <LoadingIndicator />);
};