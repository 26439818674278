import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { Layers, Map } from '@mui/icons-material';
import { emptyGuid } from '../../../models';
import { NavBreadcrumbs } from '../../core/NavBreadcrumbs';
import { FormButton, FormSection } from '..';
import { DashboardCard } from '../../Dashboard';
import { IRoofProfileFormProps } from './types';
import { RoofMap } from '../RoofMap';
import AuthenticatedComponent from '../../../auth';
import { FormInput } from '../FormFields';

export const RoofProfileForm: FunctionComponent<IRoofProfileFormProps> = (props) => {
    const { save, cancel, initValues, client, facility, reloadProfile } = props;
    const [name, setName] = useState(initValues ? initValues.name : '');
    const [isActive, setIsActive] = useState(initValues ? initValues.isActive : true);
    const [isCurrent, setIsCurrent] = useState(initValues ? initValues.isCurrent : true);
    const [svgContents, setSvgContents] = useState(initValues ? initValues.svgContents : '');
    const [needsToSaveSvg, setNeedsToSaveSvg] = useState(false);
    const [formChanged, setFormChanged] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({
        NAME: '',
    });

    useEffect(() => {
        if (svgContents) {
            setSvgContents(initValues?.svgContents ?? '');
        }
    }, [initValues, svgContents, setSvgContents]);

    useEffect(() => {
        if (needsToSaveSvg && svgContents) {
            if (name) {
                save({
                    ...initValues,
                    id: initValues ? initValues.id : emptyGuid,
                    isActive: isActive,
                    name: name,
                    isCurrent: isCurrent,
                    facilityId: facility.id,
                    svgContents: svgContents,
                });
                setNeedsToSaveSvg(false);
            }
        }
    }, [setSvgContents, facility.id, initValues, isCurrent, name, reloadProfile, save, svgContents, needsToSaveSvg, setNeedsToSaveSvg, isActive]);

    const handleIsCurrentToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsCurrent(event.target.checked);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setName(event.target.value);
    };

    const onFieldBlur = (fieldName: string) => () => {
        validate(fieldName);
    };

    const validate = (fieldName: string) => {
        let isValid = false;
        if (fieldName === 'NAME') {
            if (name) {
                fieldErrors.NAME = '';
                isValid = true;
            } else {
                fieldErrors.NAME = 'Client Name is required';
                isValid = false;
            }
        }
        setFieldErrors({
            NAME: fieldErrors.NAME,
        });
        return isValid;
    };

    const handleActiveToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormChanged(true);
        setIsActive(event.target.checked);
    };

    const formIsValid = () => {
        return validate('NAME');
    };

    const handleSave = (event: any) => {
        event.preventDefault();
        if (formIsValid()) {
            save({
                ...initValues,
                id: initValues ? initValues.id : emptyGuid,
                isActive: isActive,
                name: name,
                isCurrent: isCurrent,
                facilityId: facility.id,
                svgContents: svgContents,
            });
            setFormChanged(false);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    return (
        <Grid component='form' container direction='column' spacing={3} autoComplete='off'>
            <Grid position='sticky' item container direction='row' alignItems='center'>
                <Grid item container direction='column' justifyContent='start' xs={8}>
                    <Typography variant='h1' sx={{ marginBottom: '8px' }}>
                        <Layers /> {name ? name : 'New Roof Plan'}
                    </Typography>
                    <NavBreadcrumbs
                        links={[
                            { label: 'Home', navLink: '/' },
                            { label: 'Clients', navLink: '/clients' },
                            { label: client.name, navLink: `/clients/${client.id}` },
                            { label: facility.name, navLink: `/clients/${client.id}/facilities/edit/${facility.id}` },
                        ]}
                        currentPageLabel={name ? name : 'New Roof Plan'}
                    />
                </Grid>
                <Grid item container direction='row' justifyContent='end' alignItems='center' gap='24px' xs={4}>
                    <Grid item>
                        <FormControlLabel control={<Switch checked={isActive} onChange={handleActiveToggleChange} />} label='Active' labelPlacement='start' />
                    </Grid>
                    <Grid item>
                        {formChanged || !initValues ? (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Cancel
                            </FormButton>
                        ) : (
                            <FormButton variant='outlined' style={{ boxShadow: 'none' }} onClick={handleCancel}>
                                Close
                            </FormButton>
                        )}
                    </Grid>
                    <Grid item>
                        <FormButton itemType='button' variant='contained' color='primary' type='button' onClick={handleSave}>
                            Save
                        </FormButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <FormSection>
                    <Grid item container direction='row' alignItems='center'>
                        <Grid item xs={4}>
                            <FormInput
                                value={name}
                                onChange={handleNameChange}
                                onBlur={onFieldBlur('NAME')}
                                label='Roof Plan Name'
                                fullWidth
                                error={fieldErrors.NAME !== ''}
                                errorText={fieldErrors.NAME}
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Switch checked={isCurrent} onChange={handleIsCurrentToggleChange} />}
                                label='Current'
                                labelPlacement='start'
                                sx={{ marginTop: '14px' }}
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
            {initValues ? (
                <AuthenticatedComponent
                    requiredPermissions={['read:roofMaps']}
                    component={
                        <Grid item>
                            <DashboardCard headerTitle='Roof Map' headerIcon={<Map />}>
                                <RoofMap
                                    facility={facility}
                                    roofProfileId={initValues?.id}
                                    svgContents={svgContents}
                                    cancel={handleCancel}
                                    setFormChanged={setFormChanged}
                                    setSvgContents={setSvgContents}
                                    showTableInit={false}
                                    canLinkSections={true}
                                    reloadProfile={reloadProfile}
                                    editOnMapClick={true}
                                    setNeedsToSaveSvg={setNeedsToSaveSvg}
                                />
                            </DashboardCard>
                        </Grid>
                    }
                />
            ) : (
                <></>
            )}
        </Grid>
    );
};
